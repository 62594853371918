import React, { useState, Fragment } from "react";

import Testimoni from "../../component/Slider/Testimoni";
import Navbar from "./../../component/Navbar/Navbar";
import Reservation from "./../../component/Card/Reservation";
import FavoriteMenu from "../../component/Card/FavoriteMenu";
import Subscribe from "../../component/Card/Subscribe";
import Footer from "../../component/Footer/Footer";
import ChooseMenu from "../../component/Card/ChooseMenu";
const Menu = (props) => {
  const chooseMenu = [
    {
      title: "BBQ Extravaganza",
      price: 13,
      img: "./../images/favorite (1).jpg",
      detail: "Grilled meats with savory flavors.",
      link: "#!",
    },
    {
      title: "Egg on Noodles Fusion",
      price: 13,
      img: "./../images/favorite (2).jpg",
      detail: "Perfect fusion of textures and taste.",
      link: "#!",
    },
    {
      title: "Fluffy Pancake Delight",
      price: 13,
      img: "./../images/favorite (3).jpg",
      detail: "Served with syrup, a delight.",
      link: "#!",
    },
    {
      title: "Shrimp Sensation",
      price: 13,
      img: "./../images/favorite (4).jpg",
      detail: "Spicy shrimp dish with sizzle.",
      link: "#!",
    },
    {
      title: "Sweet Platter",
      price: 13,
      img: "./../images/favorite (5).jpg",
      detail: "Heavenly assortment of delightful desserts.",
      link: "#!",
    },
    {
      title: "Prawns Perfection",
      price: 13,
      img: "./../images/favorite (6).jpg",
      detail: "Expertly crafted, delicate seafood flavors.",
      link: "#!",
    },
  ];

  const favoriteMenu = [
    {
      "title": "Deluxe Seafood Platter",
      "price": 22,
      "img": "./../images/Image.png",
      "detail": "Savor the ocean's finest with our Deluxe Seafood Platter, a culinary masterpiece that combines freshness and flavor."
    },
    {
      "title": "Gourmet Steak Feast",
      "price": 25,
      "img": "./../images/Image-1.png",
      "detail": "Indulge in luxury dining with our Gourmet Steak Feast, where perfectly grilled steaks meet exquisite accompaniments for an unforgettable experience."
    },
    {
      "title": "Mediterranean Delight",
      "price": 18,
      "img": "./../images/Image-2.png",
      "detail": "Take a journey to the Mediterranean with our delightful cuisine, harmonizing flavors from the region to create a culinary symphony."
    },
    {
      "title": "Sushi Extravaganza",
      "price": 20,
      "img": "./../images/Image-3.png",
      "detail": "Experience the art of sushi with our Extravaganza, a selection of meticulously crafted rolls that redefine the sushi experience."
    },
    {
      "title": "Sweet Dessert",
      "price": 15,
      "img": "./../images/Image-4.png",
      "detail": "Indulge your sweet tooth with our Dessert Platter, a collection of heavenly treats that will satisfy every dessert lover's cravings."
    },
    {
      "title": "Vegetarian Delight",
      "price": 16,
      "img": "./../images/Image-5.png",
      "detail": "Discover a world of flavors with our Vegetarian Delight, a feast of plant-based creations that elevate vegetarian dining to new heights."
    }

  ];
  return (
    <Fragment>
      <div className="overflow-hidden">
        <div className="bg__black position-relative">
          <img
            src="./../images/New Project (1) 1.png"
            className="path__1"
            alt=""
          />
          <div className="position-relative z-2">
            <Navbar />
            <div className="container">
              <h5 className="mb-0 color__white normal font__size--16 text__16-1024">
                <span className="opacity__6">Home</span> {" > "}
                <span className="color__yellow">Menu</span>
              </h5>
            </div>
            <section>
              <div className="container">
                <div className="text-center">
                  <h1 className="bold font__size--80 text__80-1024 text__80-md text__80-sm text__80-xs playfair color__white mb-4">
                    Menu
                  </h1>
                  <p className="normal font__size--16 text__16-1024 color__white">
                  Savor a symphony of flavors with our diverse menu,{" "}
                    <br className="d-none d-md-block" />  meticulously crafted to satisfy every palate.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        <section className="pb-0">
          <div className="container">
            <div className="text-center mb-4">
              <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm playfair">
                Choose Your Menu
              </h2>
            </div>
            <ul
              class="wrapper__navtab-wrap nav nav-pills mb-5"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link medium font__size--20 text__20-1024 active"
                  id="pills-brunch-tab"
                  data-toggle="pill"
                  href="#pills-brunch"
                  role="tab"
                  aria-controls="pills-brunch"
                  aria-selected="true"
                >
                  Brunch
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link medium font__size--20 text__20-1024"
                  id="pills-lunch-tab"
                  data-toggle="pill"
                  href="#pills-lunch"
                  role="tab"
                  aria-controls="pills-lunch"
                  aria-selected="false"
                >
                  Lunch
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link medium font__size--20 text__20-1024"
                  id="pills-dinner-tab"
                  data-toggle="pill"
                  href="#pills-dinner"
                  role="tab"
                  aria-controls="pills-dinner"
                  aria-selected="false"
                >
                  Dinner
                </a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-brunch"
                role="tabpanel"
                aria-labelledby="pills-brunch-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="row">
                      {chooseMenu.map((obj) => {
                        return (
                          <div className="col-sm-6 col-lg-4 mb-4">
                            <ChooseMenu obj={obj} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-lunch"
                role="tabpanel"
                aria-labelledby="pills-lunch-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="row">
                      {chooseMenu.map((obj) => {
                        return (
                          <div className="col-sm-6 col-lg-4 mb-4">
                            <ChooseMenu obj={obj} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-dinner"
                role="tabpanel"
                aria-labelledby="pills-dinner-tab"
              >
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <div className="row">
                      {chooseMenu.map((obj) => {
                        return (
                          <div className="col-sm-6 col-lg-4 mb-4">
                            <ChooseMenu obj={obj} />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <Reservation />
          </div>
        </section>

        <section>
          <div className="container">
            <h2 className="text-center bold font__size--48 text__48-1024 text__48-md text__48-sm playfair mb__5">
              Our Favorite Menu{" "}
            </h2>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="row">
                  <div className="col-md-6">
                    {favoriteMenu.map((obj, i) => {
                      if (i < 3) {
                        return (
                          <div className="mb-4">
                            <FavoriteMenu data={obj} />
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className="col-md-6">
                    {favoriteMenu.map((obj, i) => {
                      if (i >= 3 && i < 6) {
                        return (
                          <div className="mb-4">
                            <FavoriteMenu data={obj} />
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <Subscribe />
          </div>
        </section>

        <Footer />
      </div>
    </Fragment>
  );
};

export default Menu;
